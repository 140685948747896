import { navigate } from "gatsby"
// Fetch
import gateway from "../fetch/gateway"

// Functions
import { dataLayerPush, lineItemEcommerceObject } from "./data-layer"
import { hardCopy } from "./util"

const createLineItemObject = (productMod, printProduct) => {
    const productId = Number(productMod.product.bigcommerce_id)
    const productName = productMod.activeColorName ? `${productMod.product.name} - ${productMod.activeColorName}` : productMod.product.name
    const variantId = Number(productMod.activeVariant)
    // Information
    const lineItemArray = []
    const metaObject = {}
    const sizeObject = {}
    let printingOptionObject = {}
    let printOptionArray = []
    let colorSetups = 0
    // Options Loop
    productMod?.product?.modifiers?.forEach(obj => {
        const { id, name, display_name, option_values } = obj
        // Products with Connect Print Product
        if ((display_name.toLowerCase().includes("tryck") && printProduct || display_name.toLowerCase().includes("brodyr")) && printProduct) {
            const optionId = productMod.activeModifier[id] ? productMod.activeModifier[id] : option_values[0].id
            const option = option_values.filter(obj => obj.id === optionId)[0]
            const { label, sort_order } = option
            if (label.toLowerCase() !== "inget tryck" || sort_order !== 0) {
                const printModifier = printProduct.modifiers.filter(obj => obj?.display_name?.toLowerCase()?.includes("tryck") || obj?.display_name?.toLowerCase()?.includes("brodyr"))[0]
                const printModiferOption = printModifier.option_values.filter(obj => obj.label.toLowerCase() === label.toLowerCase())[0]
                const printSelection = {
                    "option_id": printModiferOption.option_id,
                    "option_value": printModiferOption.id
                }
                const uniqueId = {
                    "option_id": printProduct.modifiers.filter(obj => obj.display_name.toLowerCase().includes("unique id"))[0].id,
                    "option_value": productMod.printId
                }
                lineItemArray.push({
                    "name": `${display_name} - ${label}`,
                    "print_id": productMod.printId,
                    "quantity": parseInt(productMod.quantity, 10),
                    "product_id": parseInt(printProduct.bigcommerce_id, 10),
                    "variant_id": parseInt(printProduct.variants[0].id, 10),
                    "option_selections": [printSelection, uniqueId]
                })
                // Color Setup
                const colorSetupIndex = decideColorSetup(option)
                colorSetups += colorSetupIndex
            }
            printingOptionObject = printProduct
        }
        // Products with no Print Product
        else if ((display_name.toLowerCase().includes("tryck") || display_name.toLowerCase().includes("brodyr"))) {
            const object = createPrintOption(obj, productMod)
            printingOptionObject[id] = object.printingOptionObject
            printOptionArray.push(object.printingOption)
            colorSetups += object.colorSetup
        }
        // Unique ID
        else if (name.toLowerCase() === "unique id") {
            printOptionArray.push({
                "option_id": parseInt(id, 10),
                "option_value": productMod.printId
            })
        }
        // Sizes
        else if ((name.toLowerCase().includes("size") || name.toLowerCase().includes("storlek"))) {
            sizeObject.id = id
            sizeObject.name = display_name
            sizeObject.option_values = option_values?.map(obj => {
                return {
                    id: obj?.id,
                    label: obj?.label,
                    cost: obj?.adjusters?.price?.adjuster_value ? obj.adjusters.price.adjuster_value : 0
                }
            })
        }
    })
    // Get Meta Data
    metaObject.images = []
    metaObject.comment = productMod.message
    metaObject.id = productMod.printId
    metaObject.colorSetups = colorSetups
    metaObject.bulkPricing = productMod.product.bulk_pricing_rules
    metaObject.printingOptions = printingOptionObject
    metaObject.sizes = sizeObject
    // Item with Size
    if (Object.keys(productMod?.activeSize).length > 0) Object.keys(productMod.activeSize).forEach(key => {
        const { id, option_id } = productMod.activeSize[key]
        const quantity = productMod.activeSize[key].quantity
        if (parseInt(quantity, 10) > 0) lineItemArray.push({
            "name": `${productName}`,
            "print_id": productMod.printId,
            "quantity": parseInt(quantity, 10),
            "product_id": parseInt(productId, 10),
            "variant_id": parseInt(variantId, 10),
            "option_selections": [
                {
                    "option_id": parseInt(option_id, 10),
                    "option_value": parseInt(id, 10)
                },
                ...printOptionArray
            ]
        })
    })
    // Item without Size
    else lineItemArray.push({
        "name": productName,
        "print_id": productMod.printId,
        "quantity": parseInt(productMod.quantity, 10),
        "product_id": parseInt(productId, 10),
        "variant_id": parseInt(variantId, 10),
        "option_selections": [...printOptionArray]
    })
    return { lineItems: lineItemArray, meta: metaObject }
}

const decideColorSetup = obj => {
    if (obj?.label?.toLowerCase()?.includes("1-färgstryck")) return 1
    else if (obj?.label?.toLowerCase()?.includes("2-färgstryck")) return 2
    else if (obj?.label?.toLowerCase()?.includes("3-färgstryck")) return 3
    else if (obj?.label?.toLowerCase()?.includes("4-färgstryck")) return 4
    else if (obj?.label?.toLowerCase()?.includes("5-färgstryck")) return 5
    else if (obj?.label?.toLowerCase()?.includes("6-färgstryck")) return 6
    else return obj.sort_order
}

const createPrintOption = (obj, productMod) => {
    const { id, display_name, option_values } = obj
    const optionId = productMod.activeModifier[id] ? productMod.activeModifier[id] : option_values[0].id
    const optionObj = option_values.filter(obj => obj.id === optionId)[0]
    const colorSetupIndex = decideColorSetup(optionObj)
    return {
        colorSetup: colorSetupIndex,
        printingOption: {
            "option_id": parseInt(id, 10),
            "option_value": parseInt(optionId, 10)
        },
        printingOptionObject: {
            id: id,
            name: display_name,
            option_values: option_values?.map(obj => {
                return {
                    id: obj?.id,
                    label: obj?.label,
                    cost: obj?.adjusters?.price?.adjuster_value ? obj.adjusters.price.adjuster_value : 0,
                    schablons: obj?.schablon
                }
            })
        }
    }
}

const createItemsObject = (cartObject) => {
    // If cartObject is undefined
    if (!cartObject) return {}

    // Information
    const { cart, meta } = cartObject
    const { lineItems } = cart
    const object = {}

    // Filter out data
    const items = lineItems.physical_items.filter(obj => !obj.sku.toLowerCase().startsWith("print"))
    const prints = lineItems.physical_items.filter(obj => obj.sku.toLowerCase().startsWith("print"))

    // Filter out tied Prints to the Item
    const filterOutPrints = (prints, itemUniqueId) => prints.filter(item => {
        const uniqueId = item.options.filter(obj => obj.name === "Unique ID")[0]?.value
        if (uniqueId === itemUniqueId) return item
    }).map(item => {
        return {
            id: item.id,
            price: item.extended_list_price,
            name: item.name,
            quantity: item.quantity
        }
    })

    // Filter out Print Options
    const filterOutPrintOptions = (options, itemUniqueId) => options.map(obj => {
        if (meta[itemUniqueId]?.printingOptions[obj.nameId]) {
            const { name, option_values } = meta[itemUniqueId].printingOptions[obj.nameId]
            const option = option_values.filter(option => obj.valueId === option.id)[0]
            return {
                id: option.id,
                price: option.cost,
                name: `${name} - ${option.label}`,
            }
        }
    }).filter(obj => obj?.price > 0)

    // Loop the items
    items && items.forEach(item => {
        // Get the Unique ID
        const uniqueId = item.options.filter(obj => obj.name === "Unique ID")[0]?.value ? item.options.filter(obj => obj.name === "Unique ID")[0]?.value : item.id
        // Get the Color Setups
        const colorSetups = meta[uniqueId]?.colorSetups
        // Print Product Array
        const printProductArray = filterOutPrints(prints, uniqueId)
        // Print Option Array
        const printOptionArray = filterOutPrintOptions(item.options, uniqueId)
        // Build the Object
        if (uniqueId && !object[uniqueId]) {
            object[uniqueId] = {
                uniqueId: uniqueId,
                id: item.id,
                sku: item.sku,
                name: item.name,
                price: item.extended_list_price,
                image_url: item.image_url,
                message: item.options.filter(obj => obj.id === 125)[0]?.value ? item.options.filter(obj => obj.id === 125)[0]?.value : "",
                file_upload: item.options.filter(obj => obj.id === 126)[0]?.value ? item.options.filter(obj => obj.id === 126)[0]?.value : [],
                sizes: [],
                prints: [...printProductArray, ...printOptionArray],
                printProduct: printProductArray.length > 0 ? true : false,
                option_selections: item.options,
                color_setups: colorSetups
            }
        }
        // Add Size Value as an attribute
        item.size_name = item.options.filter(obj => obj.name.startsWith("Storlek") || obj.name.startsWith("Size"))[0]?.value
        object[uniqueId].sizes.push(item)
    })
    Object.keys(object).forEach(key => {
        const item = object[key]
        let totalQuantity = 0, totalPrintPrice = 0, totalItemPrice = 0
        // Add Sizes for Total Item Price 
        item.sizes.forEach(size => {
            totalQuantity += size.quantity
            totalItemPrice += size.extended_sale_price
        })
        // Print Product
        item?.prints?.forEach(print => totalPrintPrice += print.price)
        // Push the Variables
        object[key].printPrice = totalPrintPrice / totalQuantity
        object[key].totalQuantity = totalQuantity
        object[key].totalItemPrice = totalItemPrice
        object[key].totalPrintPrice = totalPrintPrice
        object[key].totalPrice = totalItemPrice + totalPrintPrice
    })
    return object
}

const runCreateEnquiry = async (e, cartObject, deleteCart, toggleLoader, toggleError) => {
    e.preventDefault()
    toggleLoader(true)
    const customerObject = {
        "first_name": e.target.name.value,
        "surname": e.target.surname.value,
        "email": e.target.email.value,
        "phone": e.target.phone.value,
        "company_name": e.target.company_name.value,
        "company_id": e.target.company_id.value
    }
    if (cartObject.cart.numberItems < 1) toggleError(false, "Inga produkter i varukorgen")
    else await gateway({ type: "create_enquiry", cart: cartObject.cart, customer: customerObject, meta: cartObject.meta })
        .then(async (payload) => {
            deleteCart()
            toggleLoader(false)
            navigate("/order-confirm/", { replace: true, state: payload })
        }).catch(err => {
            console.log(err)
            toggleError(false, err.message)
        })
}

const runEnquirySubmit = async (e, productMod, printProduct, brands, enquirySubmit, setEnquiryPrompt, validationObject, addToCart, toggleLoader, toggleError) => {
    e.preventDefault()
    if (e.key !== 'Enter') {
        try {
            toggleLoader(true)
            const files = e.target.fileUpload.files
            let lineItemObject = createLineItemObject(productMod, printProduct)
            lineItemObject = hardCopy(lineItemObject)
            if (validationObject?.color && !productMod?.activeVariant) toggleError("choose_color", "Du måste välja en färg på artikeln")
            else if (validationObject?.size && Object.keys(productMod.activeSize)?.length === 0) toggleError("choose_size", "Du måste välja en eller flera storlekar")
            else if (!lineItemObject.lineItems || lineItemObject?.lineItems?.length <= 0) toggleError(false, "Du har inte valt några produkter")
            else if (!lineItemObject.meta) toggleError(false, "Ingen Meta Data finns. Testa igen!")
            else if (!lineItemObject.meta.id) toggleError(false, "Inget unikt ID finns. Testa igen!")
            else addToCart(lineItemObject, files)
                .then(() => {
                    toggleLoader(false)
                    // Trigger Data Layer Push
                    const dataLayerObject = lineItemEcommerceObject(productMod, brands)
                    dataLayerPush("add_to_cart", dataLayerObject, true)
                    // Navigate to cart
                    if (enquirySubmit === "addToCart") navigate("/cart/")
                    if (enquirySubmit === "enquiryCheckout") setEnquiryPrompt(true)
                })
                .catch(async err => {
                    console.log(err)
                    toggleLoader(false)
                    if (err === undefined) {
                        const dataLayerObject = lineItemEcommerceObject(productMod, brands)
                        dataLayerPush("add_to_cart", dataLayerObject, true)
                        // Navigate to cart
                        if (enquirySubmit === "addToCart") navigate("/cart/")
                        if (enquirySubmit === "enquiryCheckout") setEnquiryPrompt(true)
                    }
                    else if (JSON.stringify(err).includes("a minimum of")) {
                        toggleError("choose_quantity", `Du måste köpa minst ${productMod.order_min} stycken.`)
                    }
                    else {
                        //toggleError(false, "Något gick fel! Kontakta oss på hej@attitude.se istället.")
                        await gateway({
                            type: "error",
                            error: JSON.stringify(err),
                            data: JSON.stringify(lineItemObject)
                        })
                    }
                })
        }
        catch (err) {
            console.log(err)
            toggleLoader(false)
            toggleError(false, "Något gick fel! Kontakta oss på hej@attitude.se istället.")
            await gateway({
                type: "error",
                error: JSON.stringify(err),
                data: ""
            })
        }
    }
}

export { createLineItemObject, createItemsObject, decideColorSetup, runCreateEnquiry, runEnquirySubmit }