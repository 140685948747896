import * as React from "react"
import { Link } from "gatsby"
import CartContext from "../../context/CartProvider"
import { Navigation } from "../new-navigation"
import { TopBanner } from "../top-banner"
import { CartButton } from "./cart-button"
import { Toast } from "../toast"

// Images
import LogoSVG from "../../images/attitude-logo.svg"

// Icons
import Phone from "../../icons/phone"
import SearchIcon from "../../icons/search"

import "../../styles/header.scss"

export function Header({ location }) {
  const { state, addingToCart, cartError } = React.useContext(CartContext)
  const [openMobileMenu, setOpenMobileMenu] = React.useState(false)

  return (
    <>
      <TopBanner />
      <div id="header">
        <header className="header">
          <Link to="/" className="logo" title={"Gå till startsidan"}>
            <img src={LogoSVG} alt="Attitude.se Logo"/>
          </Link>
          <div className={"slogan"}>
            <h3><b>Experter på tryck sedan 2003</b></h3>
          </div>
          <div className={"tools"}>
            <div className="mobileMenuToggle" onClick={() => setOpenMobileMenu(!openMobileMenu)}>Meny</div>
            <Link to="/kontakta-oss/" className={"searchButton"} title={"Gå till Kontakta oss"}>
              <Phone color="#FFF" size="18" margin="10" />
            </Link>
            <Link to="/search/" className={"searchButton"} title={"Gå till Söksidan"}>
              <SearchIcon />
            </Link>
            <CartButton quantity={state?.quantity} />
          </div>
        </header>
        <div className={"navigationBar"}>
          <Navigation location={location} openMobileMenu={openMobileMenu} setOpenMobileMenu={setOpenMobileMenu} />
        </div>
        {/* {openMobileMenu ? <NavigationMobile setOpenMobileMenu={setOpenMobileMenu} /> : null} */}
      </div>
      <Toast show={addingToCart}>
        {addingToCart === "loading" ? (
          "Laddar.."
        ) : (
          <>
            {addingToCart}
            <svg
              width="14"
              height="14"
              fill="none"
              style={{ marginLeft: "10px" }}
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.019 10.492l-2.322-3.17A.796.796 0 013.91 6.304L6.628 9.14a1.056 1.056 0 11-1.61 1.351z"
                fill="var(--attitude-black)"
              />
              <path
                d="M5.209 10.693a1.11 1.11 0 01-.105-1.6l5.394-5.88a.757.757 0 011.159.973l-4.855 6.332a1.11 1.11 0 01-1.593.175z"
                fill="var(--attitude-black)"
              />
              <path
                d="M5.331 7.806c.272.326.471.543.815.163.345-.38-.108.96-.108.96l-1.123-.363.416-.76z"
                fill="var(--attitude-black)"
              />
            </svg>
          </>
        )}
      </Toast>
      <Toast show={cartError} error={cartError ? true : false}>
        {cartError}
      </Toast>
    </>
  )
}
